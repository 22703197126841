$(function () {
    const $form = $('form#search-form');

    $form.on('reset', function (event) {
        event.preventDefault();

        $form.find('input, select, textarea').prop('disabled', true).end().submit();
    })
    .on('click', 'label[for]', function (event) {
        event.preventDefault();

        $form.find(`#${this.getAttribute('for')}`).prop('checked', false).end().submit();
    })
    .on('submit', function () {
        const $form = $(this);
        const $q = $form.find('input[name="q"]');
        const $sort = $form.find('input[name="sort"][value=""]');

        if ($q.val() === '') {
            $q.prop('disabled', true);
        }
        if ($sort.prop('checked')) {
            $sort.prop('disabled', true);
        }
    });
});

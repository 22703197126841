(function () {
  var artistsList = document.getElementById('artists-list'),
      seeMoreBtn = document.getElementById('see-more-artists'),
      seeLessBtn = document.getElementById('see-less-artists'),
      hiddenClass = "u-hidden",
      revealedClass = "revealed";

  if (artistsList) {

    var artistsVisible = artistsList.getAttribute('data-max-visible'),
        artistsFeatured = artistsList.getAttribute('data-featured'),
        maxItems = artistsVisible,
        items  = artistsList.children,
        itemsTotal = items.length;

    if (itemsTotal > 5) {

      [].forEach.call(items, function(item, idx){

        if ( (artistsFeatured === 'yes') && (item.classList.contains('not-featured')) ) {
          item.classList.add(hiddenClass);
        }
        else if (maxItems > 0) {
          if (idx > maxItems - 1) {
            item.classList.add(hiddenClass);
          }
        }
        else {
          item.classList.add(hiddenClass);
          seeMoreBtn.setAttribute('data-count', '(' + itemsTotal + ')')
        }

      });


      seeMoreBtn.addEventListener('click', function(){

        [].forEach.call(document.querySelectorAll('.' + hiddenClass), function(item, idx){

          item.classList.remove(hiddenClass);
          item.classList.add(revealedClass);

          if ( document.querySelectorAll('.' + hiddenClass).length === 0) {
            seeMoreBtn.style.display = 'none';
          }

        });

      });

      seeLessBtn.addEventListener('click', function(){

        [].forEach.call(document.querySelectorAll('.' + revealedClass), function(item, idx){
        
          item.classList.remove(revealedClass);
          item.classList.add(hiddenClass);

          if ( document.querySelectorAll('.' + revealedClass).length === 0) {
            seeMoreBtn.style.display = 'block';
          }

        });

      });

    }

    else {
      seeMoreBtn.style.display = 'none';
    }  

  }

  

}());
